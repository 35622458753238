import { ConversationalForm } from 'conversational-form';
import {
  TweenLite,
} from 'gsap/TweenMax';
// import { Power3 } from 'gsap/EasePack';
import Tracking from './tracking';
import 'reset-css';
import './scss/main.scss';

const animTime = 0.8;

/* function preloadFormImages() {
  const images = [].slice.call(document.querySelectorAll('form *[cf-image]'));
  // eslint-disable-next-line no-return-assign
  images.map(el => (new Image()).src = el.getAttribute('cf-image'));
} */

function animateIn() {
  console.log('animateIn');
  const cfEl = document.querySelector('.cf');
  const casesEl = document.querySelector('.cases');

  TweenLite.set(cfEl, { y: 40 });
  TweenLite.to(
    cfEl,
    animTime * 1.2,
    {
      opacity: 1,
      y: 0,
    },
  );

  setTimeout(
    () => {
      window.ConversationalForm.start();
      TweenLite.to(
        casesEl,
        animTime * 2,
        {
          opacity: 1,
          delay: animTime,
        },
      );
    },
    animTime * 0.6 * 1000,
  );
}

function loadVideos() {
  const videos = [].slice.call(document.querySelectorAll('video'));
  videos.map((video) => {
    const sourceElem = video.querySelector('source');
    const src = sourceElem.getAttribute('data-src');
    // eslint-disable-next-line no-param-reassign
    sourceElem.src = src;
    video.load();
    return video;
  });
}

function init() {
  // preloadFormImages();

  Tracking.registerAllExternalLinks();
  setTimeout(() => {
    loadVideos();
  }, 1000);

  const wrapperEl = document.querySelector('.wrapper');
  const formEl = document.querySelector('.form');
  const cfEl = document.querySelector('.cf');

  // eslint-disable-next-line no-unused-vars
  const cfInstance = new ConversationalForm({
    formEl,
    context: cfEl,
    loadExternalStyleSheet: false,
    preventAutoFocus: true,
    preventAutoStart: true,
    submitCallback: () => {
      console.log('done');
      cfInstance.addRobotChatResponse('Thank you. I\'ll be in touch.');
      cfInstance.addRobotChatResponse('Have a wonderful day.');
    },
    flowStepCallback: (dto, success) => {
      console.log('fsc', dto.tag.name, dto.tag.value);

      setTimeout(() => {
        if (
          dto.tag.name !== 'changeThemeAgain'
          || (
            dto.tag.name === 'changeThemeAgain'
            && dto.tag.value[0] !== 'yes'
          )
        ) {
          success();
        }

        if (
          dto.tag.name === 'changeThemeAgain'
          && dto.tag.value[0] === 'yes'
        ) {
          window.ConversationalForm.remapTagsAndStartFrom(3);
        }
      }, 0);
    },
  });

  TweenLite.to(
    wrapperEl,
    1,
    {
      opacity: 1,
      // ease: Power1,
      delay: animTime / 2,
      onComplete: animateIn,
    },
  );
}

document.addEventListener('DOMContentLoaded', init);
